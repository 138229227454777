<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-07 09:43:22
 * @LastEditors: ------
 * @LastEditTime: 2021-08-17 15:41:04
-->
// 支付方式

<template>
  <div class="big_box">
    <TitleNav class=" ">
      <template #one>
        <span @click="go('home')" class="cursor">
          首页 >
        </span>
      </template>
      <template #two>
        <span @click="go('serveDetail')" class="cursor">
          服务合同管理 >
        </span>
      </template>
      <template #three>
        <span @click="go" class="cursor"> {{ type_home }}详情页 </span>
      </template>
      <template v-slot:slotCon>
        <div class="pay_box bg_white_color">
          <div class="pay_top">
            <img src="../image/pay.png" alt="" />
            <span class="base_333_color ">支付方式</span>
          </div>
          <div class="pay_con">
            <ul class="base_666_color">
              <li>
                <span>付款类型：</span>
                <span @click="btn_type">
                  <span class="circle cursor" ref="solid"> </span>
                  <span class="solid cursor" v-if="isShow2"></span>
                  <span class="cursor">按月结算</span>
                </span>
              </li>
              <li>
                <span>劳务费单价：</span>
                <span>
                  <input type="text" placeholder="请输入单价" v-model="value" />
                  元/月
                  <span class="RMB base_color3"
                    >（ 人民币大写：{{ value ? RMB : "零圆" }} ）</span
                  >
                </span>
              </li>
              <!-- <li>
                <span>
                  首次付款时间：
                </span>
                <span> YYY / MM / DD</span>
              </li> -->
              <li>
                <span>
                  付款周期：
                </span>
                <span>
                  每月
                  <a-select default-value="请选择" @change="handleChange">
                    <a-select-option
                      :value="item"
                      v-for="(item, index) of 20"
                      :key="index"
                    >
                      {{ item }} 日
                    </a-select-option>
                  </a-select></span
                >
              </li>
              <li class="cursor">
                <span
                  :class="isShow ? 'checked checked1' : 'checked'"
                  @click="checked"
                >
                </span>
                <img
                  src="../image/yes.png"
                  alt=""
                  v-if="isShow"
                  class="show_img"
                  @click="checked"
                />
                <span class="check_con" @click="checked">
                  雇主同意购买并签订《三方责任险》</span
                >
              </li>
              <li class="cursor">
                <span
                  :class="isShow1 ? 'checked checked1' : 'checked'"
                  @click="checked1"
                >
                </span>
                <img
                  src="../image/yes.png"
                  alt=""
                  v-if="isShow1"
                  class="show_img"
                  @click="checked1"
                />
                <span class="check_con" @click="checked1">
                  雇主同意购买并签订《雇主责任险》</span
                >
                <span class="preview  base_color3" @click="showModal">
                  <!-- 预览合同 -->
                </span>

                <a-modal
                  :visible="visible"
                  title="预览合同"
                  ok-text="确认"
                  cancel-text="取消"
                  @ok="hideModal"
                  @cancel="cancel"
                  width="90%"
                  :ok-button-props="{ style: { display: 'none' } }"
                  :cancel-button-props="{ style: { display: 'none' } }"
                >
                  <div>{{ returnedTarget }}</div>
                </a-modal>
              </li>
            </ul>
          </div>
          <div class="btn base_white_color">
            <button class="back cursor" @click="go('chooseServe')">
              返回
            </button>
            <button class="create cursor" @click="test">生成服务合同</button>
          </div>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn ">
      <template #one>
        <button @click="goContract" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template>
      <template #two>
        <button @click="createUser" class="btn2 cursor base_white_color">
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽
import toMoney from "../utils/toMoney";
import { getItem } from "../utils/storage";
import { postContract } from "../api/api";

export default {
  name: "payment",
  data() {
    return {
      isShow: false,
      isShow1: false,
      isShow2: true,
      value: "",
      RMB: "",
      pay: {
        orderFinalPayDate: "",
        orderPrice: "",
        storeId: getItem("userInfo").storeId,
        monthAmount: "",
        totalAmount: "",
        salesman: getItem("userInfo").id,
      },
      contractInfo: getItem("contract") || null,
      serveInfo: getItem("serveInfo") || null,
      visible: false,
      returnedTarget: null,
      period: null,
      type_home: "",
    };
  },
  components: { TitleNav, ebutton },
  created() {
    // this.serveInfo = getItem("serveInfo");
    // this.contractInfo = getItem("contract");
    if (this.serveInfo == null || this.serveInfo.num == null) {
      this.$error({
        title: "信息填写缺失!",
        content: "请将此前页面信息填写完整!",
      });
      return false;
    }
    let res = getItem("typeNum");
    if (res == 0) {
      this.type_home = "钟点服务";
    } else if (res == 1) {
      this.type_home = "短期应急(医院陪护)";
    } else if (res == 2) {
      this.type_home = "24小时生活照护";
    } else if (res == 3) {
      this.type_home = "适老无障碍设施改造";
    }
  },
  methods: {
    showModal() {
      //预览合同
      if (this.serveInfo == null || this.serveInfo.num == null) {
        this.$error({
          title: "信息填写缺失!",
          content: "请将此前页面信息填写完整!",
        });
        return false;
      }
      if (this.value == "") {
        this.$message.error("请填写劳务费单价!");
        return false;
      }
      if (this.period == null) {
        this.$message.error("请选择付款周期!");
        return false;
      }
      if (this.isShow && this.isShow1) {
        this.visible = true;
      } else {
        this.$message.error("请同意责任协议!");
        return false;
      }

      // this.getLocal();
    },
    hideModal(e) {
      console.log(e); //预览合同确定
      this.visible = false;
    },
    cancel(e) {
      console.log(e); //预览合同取消
      this.visible = false;
    },
    checked() {
      this.isShow = !this.isShow; //三方责任险
    },
    checked1() {
      this.isShow1 = !this.isShow1; // 雇主责任险
    },
    btn_type() {
      // this.isShow2 = !this.isShow2; //现在默认一条选项--按月结算
      if (this.isShow2 == true) {
        this.$refs.solid.style.border = "1px solid var(--themeColor)";
      } else {
        this.$refs.solid.style.border = "1px solid #ccc";
      }
    },
    go(key) {
      switch (key) {
        case "home":
          this.$router.push("/home");
          break;
        case "serveDetail":
          this.$router.push("/serveDetail");
          break;
        case "chooseServe":
          this.$router.push("/chooseServe");
          break;
        default:
          break;
      }
    },
    goContract() {
      this.$router.push("/serveManage");
    },
    createUser() {
      this.$router.push("/infoOne");
    },
    test() {
      if (this.serveInfo == null || this.serveInfo.num == null) {
        this.$error({
          title: "信息填写缺失!",
          content: "请将此前页面信息填写完整!",
        });
        return false;
      }
      if (this.value == "") {
        this.$message.error("请填写劳务费单价!");
        return false;
      }
      if (this.period == null) {
        this.$message.error("请选择付款周期!");
        return false;
      }
      if (this.isShow && this.isShow1) {
        this.getLocal();
      } else {
        this.$message.error("请同意责任协议!");
        return false;
      }
    },
    getLocal() {
      let that = this;

      that.pay.monthAmount = that.value;
      that.pay.totalAmount = that.serveInfo.num * that.value;

      const pageTwo = Object.assign(that.contractInfo, that.serveInfo);
      //判断此前两个页面的信息填写是否完整
      console.log(pageTwo);
      if (
        !Object.keys(pageTwo).some(function(index) {
          if (pageTwo[index].length == 0) {
            return true;
          }
        })
      ) {
        that.$message.error("请将此前页面信息重新填写完整!");
      }
      that.returnedTarget = Object.assign(
        that.contractInfo,
        that.serveInfo,
        that.pay
      );
      postContract(that.returnedTarget).then((res) => {
        //生成服务合同接口
        if (res.data.success) {
          that.$success({
            title: res.data.message,
            // JSX support
            content: (
              <div>
                <br /> <p>订单创建成功! </p>
                <p>请勿重复创建订单! </p>
              </div>
            ),
            onOk() {
              console.log("ok");
              that.$router.push("/serveManage");
            },
          });
          // this.value = "";
          // this.RMB = "";
          that.isShow = false;
          that.isShow1 = false;
          that.$store.commit("setContract", null);
          that.$store.commit("setServeInfo", null);
          that.$store.commit("setTypeNum", null);
        } else {
          that.$error({
            title: "订单创建出现问题!",
            content: res.data.message,
          });
          // this.value = "";
          // this.RMB = "";
          that.isShow = false;
          that.isShow1 = false;
        }
      });
    },

    trans(value) {
      // value 值条件限定
      let flag = new RegExp("^[1-9]([0-9])*$").test(value);
      if (!flag || value > 100000) {
        this.$message.error("劳务费单价需为正整数且不超过10万元整!");
        this.value = "";
      } else {
        this.RMB = toMoney.required(value); //转换数字为大写
      }
    },
    handleChange(e) {
      // 付款周期每月几号
      this.period = e;
      this.pay.orderFinalPayDate = this.period;
    },
  },
  watch: {
    //监听最新一次劳务单价
    value(newVal) {
      this.trans(newVal);
      this.pay.orderPrice = newVal;
    },
  },
};
</script>

<style scoped>
.big_box {
  position: relative;
}

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
/* ------------------------------------------------------------------------------------------- */

.pay_box {
  width: 1520px;
  height: 627px;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  padding: 46px 42px;
}
.pay_top {
  padding-bottom: 34px;
  border-bottom: 1px solid #e2e2e2;
}
.pay_top img {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
}
.pay_top span {
  font-size: 22px;
}
.pay_con {
  font-size: 20px;
  text-align: center;
  margin-top: 16px;
}
.pay_con ul {
  display: inline-block;
}
.pay_con ul li {
  height: 48px;
  display: flex;
  line-height: 48px;
  margin-top: 12px;
  position: relative;
}
.pay_con ul li span:nth-child(1) {
  margin-right: 10px;
}
input::placeholder {
  font-size: 20px;
  color: #cacaca;
}
input {
  width: 352px;
  outline: none;
  border: 1px solid#D7D7D7;
  border-radius: 8px;
  padding-left: 20px;
}
input:focus {
  border: none;
  box-shadow: 0px 0px 4px var(--themeColor);
  border: 1px solid var(--themeColor);
}
input:hover {
  border: 1px solid var(--themeColor);
}
.checked {
  width: 26px;
  height: 26px;
  border: 1px solid #d7d7d7;
  margin-top: 10px;
  border-radius: 4px;
}
.checked1 {
  border: 1px solid var(--themeColor);
}
.check_con {
  margin-left: 10px;
}
.show_img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 12px;
  left: 2px;
}
.RMB {
  font-size: 18px;
}
.preview {
  margin-left: 10px;
}
.btn {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}
.back {
  width: 257px;
  height: 64px;
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
  border-radius: 10px;
  margin-right: 56px;
}
.create {
  width: 257px;
  height: 64px;
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
  border-radius: 10px;
}
.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid var(--themeColor);
  border-radius: 50%;
  vertical-align: middle;
}
.solid {
  display: inline-block;
  width: 12px;
  border-radius: 50%;
  height: 12px;
  background-color: var(--themeColor);
  position: absolute;
  left: 114px;
  top: 20px;
}

:deep(.ant-select) {
  width: 140px;
  height: 48px !important;
  text-align: left;
}
:deep(.ant-select-selector) {
  height: 48px !important;
  border-radius: 8px !important;
  border: 1px solid #d7d7d7 !important;
}
:deep(.ant-select-selector::before) {
  content: "";
  height: 48px;
  width: 48px;
  background-color: rgba(204, 204, 204, 0.527);
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: -1px;
}
:deep(.ant-select-selection-item::before) {
  position: absolute;
  content: "";
  height: 0px;
  width: 0px;
  border: 8px solid;
  border-color: #ccc transparent transparent transparent;
  right: 5px;
  top: 18px;
}
:deep(.ant-select-selector:hover) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  border: 1px solid var(--themeColor) !important;
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}
:deep(.ant-select-selection-item) {
  height: 48px !important;
  line-height: 46px !important;
  font-size: 20px !important;
  margin-left: 8px;
}
:deep(.ant-select-arrow) {
  display: none;
}
:deep(.anticon-close-circle svg) {
  display: none;
}
</style>
